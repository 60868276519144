import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"

const Footer = () => {
  const footerLinks = [
    {
      //title: "Company",
      items: [
        { displayText: "About", url: "" },
        {
          displayText: "Testimonials",
          url: "",
        },
      ],
    },
    {
      //title: "Region",
      items: [
        { displayText: "Boston", url: "" },
        { displayText: "New York", url: "" },
      ],
    },
    {
      // title: "Help",
      items: [
        { displayText: "Contact Recuperet", url: "mailto:hello@recuperet.io" },
        { displayText: "Help", url: "mailto:hello@recuperet.io" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <Box className="footer">
        <Grid
          container
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            minHeight: 100,
            color: "#FFF",
          }}
        >
          <Grid
            xs={12}
            sm={6}
            item
            container
            direction="column"
            justify="space-evenly"
            style={{ padding: 10 }}
          >
            <Typography variant="body2" style={{ padding: 5 }} color="inherit">
              Recuperet Health <br />
              178 Columbus Ave #230634 <br />
              New York, NY 10023 <br />
              <a style={{ color: "#fff" }} href="mailto:hello@recuperet.io">
                hello@recuperet.io
              </a>{" "}
              |{" "}
              <a style={{ color: "#fff" }} href="tel:+18333461511">
                +1 (833) 346-1511
              </a>
            </Typography>
            <Typography
              style={{ padding: 5 }}
              variant="caption"
              color="inherit"
            >
              © 2022 Recuperet. All rights reserved.
            </Typography>
          </Grid>
          <Hidden xsDown>
            {footerLinks.map(footerMenu => (
              <Grid
                item
                xs={12}
                sm={2}
                container
                style={{ padding: 10 }}
                direction="column"
                justify="space-evenly"
                key={footerMenu.title}
              >
                <Typography variant="subtitle1" color="inherit">
                  {footerMenu.title}
                </Typography>
                {footerMenu.items.map(link => (
                  <Link
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    variant="body2"
                    key={link.displayText}
                    href={link.url}
                  >
                    {link.displayText}
                  </Link>
                ))}
              </Grid>
            ))}
          </Hidden>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
export default Footer
